<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-button type="primary" size="small" @click="exportTable">导出</el-button>
    </div>
    <bsp-table :data.sync="list" ref="accountTable"
               :ciic-data-filter.sync="listQuery"
               :ciic-columns="tableColumn"
               @ciic-data-refresh="_getListpage">
    </bsp-table>
  </div>
</template>

<script>
    import bspTable from "@/components/bsp/bsp-table/bsp-table";
    import bspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
    import {getdynamicTable ,getdynamicList } from '@/api/entrust/detailImport'
    import { mapGetters } from 'vuex';
    export default {
        name: "table",
      // eslint-disable-next-line vue/no-unused-components
        components: {bspTableColumn, bspTable},
        created(){
           this._getdynamicTable();
           this._getdynamicList();
        },
        mounted() {
        },
        computed: {
          ...mapGetters([
            'dicts',
          ]),
        },
        data(){
            return{
                dialogFormVisible: false,
                showTable: false,
                list: [],
                idcardList: [],
                tableColumn:[],
                listQuery:{
                  limit: 50,
                  page: 1,
                  total: 0,
                },
                listForm: {},
                id:'',
                textMap: {
                    add: '新增',
                    update: '编辑'
                },
                dialogStatus: 'add',
              tablePar:{
                batchId:'',
                bizType:'',
                status:'',
              },
              params:{
                batchImportId:this.$route.query.batchId,
                bizType:this.$route.query.bizType,
                cusId:this.$route.query.cusId,
              },
            }
        },
        methods: {
            //获取列表数据
            _getdynamicList(){
              getdynamicList(this.params).then(res => {
                this.showTable = true
                this.listQuery.total = res.data.total
                this.list = res.data.records
              })
            },
             //获取列表表头数据
            _getdynamicTable(){
              console.log("查看对应的批次id："+this.params.batchImportId);
              getdynamicTable(this.params).then(res => {
                if(res.data.data.success=="true"){
                  this.tableColumn=res.data.data.tableColumn;
                }
              })
            },
        }
    }
</script>

<style scoped>

</style>
