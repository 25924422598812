import request from '@/router/axios';
const urlPrefix = ''

/**
 * 获取批次信息
 * @param obj
 */
export function getDetailImportBatchList(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/detail-import-batch-list-page',
    method: 'post',
    data: obj
  })
}

//单条操作导出
export function exportDetails(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/export-details',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}


/**
 * 删除批次信息
 * @param obj
 */
export function deleteImportBatch(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/delete-import-batch-by-id',
    method: 'post',
    params: obj,
  });
}

//批量下载模板
export function downloadExcelTmpl(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/down-template',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}

//单客户下载模板
export function downloadCusTemp(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/down-cus-template',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}

//导入明细的第一个 下一步
export function importStepOne(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/import-step-one',
    method: 'post',
    data: obj
  })
}

//导入明细的第二个 下一步
export function importStepTwo(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/import-step-two',
    method: 'post',
    data: obj
  })
}

//点击完成进行导入
export function importDetail(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/import-detail',
    method: 'post',
    data: obj
  })
}

//点击批次查看-获取表头
export function getdynamicTable(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/get-dynamic-table',
    method: 'post',
    data: obj
  })
}

//点击批次查看-获取表头
export function getPayoutDynamicTable(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/get-payout-dynamic-table',
    method: 'post',
    data: obj
  })
}

//点击批次查看-获取数据
export function getdynamicList(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/get-dynamic-list',
    method: 'post',
    data: obj
  })
}

//点击提交
export async function commitBatch(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/commit-batch',
    method: 'post',
    data: obj
  })
}
//部分提交
export function commitPartBatch(obj) {
  return request({
    url:urlPrefix+ '/wt/detailImport/commit-part-batch',
    method: 'post',
    data: obj
  })
}

//查询含有自定义项的客户列表
export function getCustomCusList() {
  return request({
    url: urlPrefix + '/wt/detailImport/get-custom-cus-list',
    method: 'post',
  });
}

//重新计算
export function recalculate(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/recalculate',
    method: 'post',
    data: obj
  });
}

//大库/客户名义报税点击查看增加客户列表
//detail-import-batch-cus-list-page
export function getDetailImportBatchCusList(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/detail-import-batch-cus-list-page',
    method: 'post',
    data: obj
  });
}

//查询委托机构的发票信息
export function getBillsInfo(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/get-bills-info',
    method: 'post',
    data: obj
  });
}

export function getFinanceConfirmInfo(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/get-finance-confirm-info',
    method: 'post',
    data: obj
  });
}

/**
 * 提交分支财务确认
 * @param obj
 * @param batchImportIds
 */
export function commitFinanceConfirm(obj, parameter) {
  return request({
    url: urlPrefix + '/wt/detailImport/commit-finance-confirm',
    method: 'post',
    data: {
      obj,parameter
    }
  });
}

//工资单下载
export function getEmpSalaryPath(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/get-emp-salary-path',
    method: 'post',
    data: obj
  });
}

export function downEmpSalary(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/down-emp-salary',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  });
}

//在线编辑
export function updateDetail(obj) {
  return request({
    url: urlPrefix + '/wt/detailImport/update-detail',
    method: 'post',
    data: obj
  });
}

//平均工资列表
export function averageWageList(obj) {
  return request({
    url: urlPrefix + '/wt/averagewage/averagewage-list',
    method: 'post',
    data: obj
  });
}

//平均工资列表
export function averageWageDetil(obj) {
  return request({
    url: urlPrefix + '/wt/averagewage/averagewage-detil',
    method: 'post',
    data: obj
  });
}

//平均工资导出
export function exportAverageWageDetails(obj){
  return request({
    url: urlPrefix+'/wt/averagewage/export-details',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}

//查询当前登录人所负责的客户
export function selectCusNameByUserId(obj){
  return request({
    url: urlPrefix+'/wt/averagewage/cuslist',
    method: 'post',
    data: obj
  })
}

export function checkIsExistNotBJTaxEmp(obj) {
  return request({
    url: urlPrefix+'/wt/detailImport/checkIsExistNotBJTaxEmp',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function checkIsExistOnTheWayEmp(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/checkIsExistOnTheWayEmp',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function checkBatchCusIsRelOmsCus(obj){
  return request({
    url: urlPrefix+'/checkBatchCusIsRelOmsCus',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function checkIsExistOnTheWayEmps(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/checkIsExistOnTheWayEmps',
    method: 'post',
    data: obj
  })
}

export function getCusEmpSocialSecurityInfo(obj){
  return request({
    url: urlPrefix+'/getCusEmpSocialSecurityInfo',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function checkPtCusIsRelOmsCus(obj){
  return request({
    url: urlPrefix+'/checkPtCusIsRelOmsCus',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function getEmpSocialSecurityInfo(obj){
  return request({
    url: urlPrefix+'/getEmpSocialSecurityInfo',
    method: 'post',
    data: {
      "json": obj
    }
  })
}

export function checkEmpSseIsOntheWay(obj) {
  return request({
    url: urlPrefix+'/checkEmpSseIsOntheWay',
    method: 'post',
    data: {
     obj
    }
  })
}

export function checkEmpSseIsSalary(obj){
  return request({
    url: urlPrefix+'/checkEmpSseIsSalary',
    method: 'post',
    data: obj
  })
}

export function checkBatchEmpSseIsSalary(obj){
  return request({
    url: urlPrefix+'/checkBatchEmpSseIsSalary',
    method: 'post',
    data: {
      "jsonList" : obj
    }
  })
}

// 添加模板匹配记录信息
export function addMatchRecord(obj){
  return request({
    url: urlPrefix+'/wt/templateMatch/add-match-record',
    method: 'post',
    data: obj
  })
}

// 批次撤回
export function WithdrawalBatch(obj){
  return request({
    url: urlPrefix+'/wt/detailImport/withdrawal-Wage-batch',
    method: 'post',
    data: obj
  })
}

export function checkBatchIsAllowDownload(obj) {
  return request({
    url: urlPrefix+'/wt/batchCopy/checkBatchIsAllowDownload',
    method: 'post',
    data: obj
  })
}

export function downloadSalaryTmpFile(obj) {
  return request({
    url: urlPrefix+'/wt/batchCopy/downloadSalaryTmpFile',
    method: 'post',
    responseType: 'arraybuffer',
    data: obj
  })
}

// 是否已被复制
export function getIsCopy(obj) {
  return request({
    url:urlPrefix + '/wt/batchCopy/getIsCopy',
    method: 'post',
    data: obj
  })
}

// 查询工资所属月份
export function queryWageMonth(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/queryWageMonth',
    method: 'post',
    data: obj
  })
}
//批量下载薪资校验月份是否一致
export function checkWageMonthIsSame(obj){
  return request({
    url:urlPrefix + '/wt/batchCopy/checkMonthIsSame',
    method: 'post',
    data: obj
  })
}

export function selectTrusteeOrgTips(obj){
  return request({
    url:urlPrefix + '/trustAgreement/selectTrusteeOrgTips',
    method: 'post',
    data: obj
  })
}

